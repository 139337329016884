<template>
  <div id="FloorIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.keyword" show-action placeholder="请输入学生姓名或学号查询" @search="onSearch">
        <template #action>
          <div @click="toStuSearch">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell @click="toDetail(row)">
          <template #title>
            <span class="custom-title hidden-text">
              <svg-icon icon-class="floor" size="20" style="margin-right:5px" />{{row.floorName}}
            </span>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text">
              <van-icon name="label-o" size="14" /><span style="margin-left:5px">{{row.schoolName +" | " +row.floorCode}}</span>
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="apps-o" size="14" /><span style="margin-left:5px">{{(row.floorLevel?row.floorLevel+ "层":'一层')+" | " +(row.roomCount?row.roomCount+'个房间':"0个房间")}}</span>
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.useType ===1 ||row.useType ==='1'  " class="audit success-color b-color-success">男生</span>
            <span v-else class="audit warning-color b-color-warning">女生</span>
            <div class="room-div">
              <div><span class="room-div-mian-text">{{row.intoCount}}</span><span class="room-div-other-text">{{" / "+row.bedCount}}</span></div>
              <div>已入住/总床位</div>
              <van-icon class="room-count" name="arrow" size="15" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  name: "FloorIndex",
  mixins: [initData],
  data() {
    return {
      value: "",
    };
  },
  created() {},
  methods: {
    beforeInit() {
      this.url = "/api/apartment";
      this.sort = ["floorCode,asc"];
      return true;
    },
    onClickLeft() {},
    toDetail(data) {
      this.$router.push({
        path: "/gy/g02",
        query: {
          title: data.floorName,
          id: data.id,
          floorCode: data.floorCode,
          campusName: data.campusName,
        },
      });
    },
    toStuSearch() {
      if (this.query.keyword) {
        this.$router.push({
          path: "/gy/g04",
          query: {
            title: "学生入住信息查询",
            type: 1,
            campusName: 1,
            stuName: this.query.keyword,
          },
        });
      } else {
        this.toQuery();
      }
    },
    // 检索
    onSearch() {},
  },
};
</script>

<style lang='scss' scoped>
@import "~@/assets/styles/apartment/floor.scss";
</style>
